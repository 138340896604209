import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"
import { appendScript } from "../assets/js/helper"
import Img from "gatsby-image"

const LoginPage = ({ pageContext }) => {
  const data = useStaticQuery(GetLoginData)

  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [subDomain, setsubDomain] = useState("")
  const [isRender, setIsRender] = useState(false)
  const loginMessage = "Your Username / Password is incorrect"

  const { kontentItemSettings, location, kontentItemImage } = data
  const [logo] = kontentItemSettings.elements.alternative_logo.value
  const office = location.elements.name.value
  const [laptopImg] = kontentItemImage.elements.single_image.value
  const windowAvailable = typeof window !== "undefined"
  /* eslint-disable */
  const handleSubmit = e => {
    e.preventDefault()
    /* eslint-disable */
    let auth = PestportalsClient.getAuthenticatedPortalLink(user, password)
    auth.then(response => {
      if (response.success) {
        document.location.href = response.loginLink
      } else {
        windowAvailable && window.location.replace("/login?error=invalid")
      }
    })
  }

  const script = () => {
    !window.jQuery &&
      appendScript({
        id: "jquery-3.6.0.min.js",
        scriptToAppend: "https://code.jquery.com/jquery-3.6.0.min.js",
        isAsync: false,
      })

    if (window.jQuery && typeof PestportalsClient !== "object") {
      appendScript({
        id: "pestportalsClient.js",
        scriptToAppend:
          "https://lmk.pestroutes.com/resources/js/lobster/pestportalsClient.js",
        isAsync: true,
      })
    } else {
      setTimeout(script, 200)
    }
  }

  useEffect(() => {
    if (isRender) {
      script()
      fetch("https://lmk.pestroutes.com/lobster/getConnectedOffices")
        .then(response => response.json())
        .then(r => {
          setsubDomain(Object.keys(r.connectedOffices.dbOffices)[0])
        })
    } else {
      setIsRender(true)
    }
  }, [isRender])

  return (
    <Layout location={pageContext.slug}>
      <SEO title="login" noIndex={true} />
      <section className="loginHero">
        <div className="container loginSection">
          <div className="welcome-wrapper">
            Thank you for choosing <br />
            <strong className="company_name">{office}</strong>
          </div>

          <div className="loginWrapper">
            <div className="loginFormWrapper">
              <div className="pp-text-center login-logo">
                <img src={logo.url} alt={logo.description} />
              </div>
              <form id="customLoginForm" onSubmit={handleSubmit}>
                <label htmlFor="accountNumber">
                  <span>Account Number</span>
                </label>
                <input
                  value={user}
                  name="accountNumber"
                  placeholder="Account Number"
                  type="text"
                  onChange={e => setUser(e.target.value)}
                />

                <label htmlFor="password">
                  <span>Password</span>
                </label>
                <input
                  value={password}
                  name="password"
                  placeholder="password"
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                />
                <div className="login-subtext">
                  Don't know your password?{" "}
                  <a
                    href={`https://${subDomain}.pestportals.com/forgotPassword`}
                    target="_blank"
                    id="forgotpass"
                  >
                    Get Login Info
                  </a>
                </div>
                <div className="pp-text-center">
                  <button type="submit" className="login-button">
                    Login
                  </button>
                  <div id="loginMessage">
                    {windowAvailable &&
                      window.location.href.includes("?error=invalid") &&
                      loginMessage}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="info-section">
        <div className="pp-info">
          <div className="pp-info-header pp-text-right">
            <strong>Login</strong> to manage your account
          </div>

          <div className="container-colored-line-two">
            <hr className="colored-line-two" />
            <div className="add-colored-line-two"></div>
          </div>
          <div className="container-item pp-text-right">
            <div className="item">
              <div className="icon icon-thumbsup"></div>
              <p className="item-description wow">
                <strong>Manage Billing</strong>
                <br />
                Update credit card information, make payments, and see
                transaction history. <br />
              </p>
            </div>

            <div className="item">
              <div className="icon icon-heart"></div>
              <p className="item-description">
                <strong>View Service History</strong>
                <br />
                See detailed history, leave feedback for technicians, and print
                service notifications.
              </p>
            </div>

            <div className="item">
              <div className="icon icon-info"></div>
              <p className="item-description">
                <strong>Update Info</strong>
                <br />
                Update basic account info and set communication preferences.
              </p>
            </div>
          </div>
        </div>

        <div className="pp-image pp-text-right">
          <Img fluid={laptopImg.fluid} alt={laptopImg.description} />
        </div>
      </section>
    </Layout>
  )
}

const GetLoginData = graphql`
  query GetLoginData {
    kontentItemSettings {
      elements {
        alternative_logo {
          value {
            url
            description
          }
        }
      }
    }
    location: kontentItemOffice(system: { name: { eq: "Main Office" } }) {
      elements {
        phone_number {
          value
        }
        name {
          value
        }
      }
    }
    kontentItemImage(system: { name: { eq: "Laptop image" } }) {
      elements {
        single_image {
          value {
            description
            fluid(maxWidth: 1200, quality: 80) {
              ...KontentAssetFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default LoginPage
